import { reactive, Ref, ref } from 'vue'
import { Study, createStudyProps, PageResponse } from 'momai'
import { timeFormat } from '@/utils/util'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { list, Param, createParam } from '@/api/study'
import { getCompanyId } from '@/utils/auth';

const totalNum = ref(-1)
const study = ref<Study[]>([])
const useLoadListEffect = (qryParam: Param) => {
  study.value = []
  const fetch: FetchFunction = (pageData, loadEnd) => {
    const { courseId, quality, myFollow, courseType } = qryParam
    const companyId: number = Number(getCompanyId())
    list({ courseId, quality, myFollow, courseType, pageData, companyId})
      .then(({ records, total }: PageResponse<Study>) => {
        const result = records?.map((item: any) => {
          // 封装头部数据
          const resp: Study = reactive(createStudyProps())
          resp.name = item.userName
          resp.userAvatar = item.userAvatar
          resp.type = 'study'
          resp.userId = item.userId
          resp.hasFollowing = item.hasFollowing
          resp.isGood = false
          resp.position = item.position ?? '这个人很懒，什么都没有留下'
          // 封装详情
          resp.text = item.text
          // detailResp.audioUrl =
          resp.imgs = item.img ? item.img.split(',') : []
          resp.poster = item.videoCover
          resp.courseserialName = item.courseserialName
          resp.videoUrl = item.video
          resp.courseserialId = item.courseserialId
          // 封装评论
          resp.ordId = item.id
          resp.userId = item.userId
          resp.ordType = 1
          resp.hasDz = item.workDzColor === '1'
          resp.hasSc = item.workScColor === '1'
          resp.teaEva = item.teaEva
          resp.teaPic = item.teaPic
          resp.teaVoice = item.teaVoice
          resp.scCnt = item.workScCnt
          resp.dzCnt = item.workDzCnt
          resp.fxCnt = item.workShareCnt
          resp.pjCnt = item.workPjCnt
          resp.createTime = item.createTime ? timeFormat(item.createTime) : ''
          resp.isQuality = item.rsrv1 === '1'
          //点评导师信息
          resp.teacherName = item.teacherName
          resp.teacherAvatar = item.teacherAvatar
          resp.teacherId = item.teacherId
          return resp
        })
        totalNum.value = total
        study.value.push(...result)
        loadEnd()
      })
  }
  const { loadMore, finished, loading } = useLoadList({
    fetch,
    total: totalNum,
    list: study
  })
  return { loadMore, finished, loading, study }
}

const checkFollow = (study: Ref<Study[]>, userId: number) => {
  for (const stu of study.value) {
    if (stu.userId === userId) {
      stu.hasFollowing = !stu.hasFollowing
    }
  }
}

export { useLoadListEffect, Param, createParam, checkFollow }
