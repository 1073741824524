

  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'ComBiz',
    props: {
      loadMore: {
        type: Function,
        default: () => {}
      },
      finished: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      study: {
        type: Array,
        default: []
      }
    },
  })
