

import { defineComponent, provide, ref } from 'vue'
import ComBiz from '@/views/study/components/ComBiz.vue'
import { createParam, Param } from '@/api/study';
import { useLoadListEffect, checkFollow } from '@/views/study/components/comBiz';
import { Study } from 'momai';

export default defineComponent({
  name: 'Recommend',
  components: { ComBiz},
  setup () {
    let stu = ref<Study[]>([])
    let loadMore4Study = () => {}
    let finished4Study = ref(false);
    let loading4Study = ref(false);
    const qryParams: Param = createParam();
    qryParams.pageData.current = 1
    qryParams.pageData.size = 10

    const resp = useLoadListEffect(qryParams)
    loadMore4Study = resp.loadMore
    stu.value = resp.study.value
    finished4Study.value = resp.finished.value
    loading4Study.value = resp.loading.value

    const changeFollow = (res: any) => {
      checkFollow(stu, res.userId)
    }
    provide('changeFollow', changeFollow)

    return {
      loadMore4Study,
      finished4Study,
      loading4Study,
      stu
    }
  }
})
