

import { defineComponent, ref } from 'vue'
import Attention from '@/views/study/components/attention/Index.vue'
import Discover from '@/views/study/components/discover/Index.vue'
import Recommend from '@/views/study/components/recommend/Index.vue'
import { Ref, UnwrapRef } from '@vue/reactivity';
import { changeShareInfo } from '@/utils/wechat'
import { useRouter } from "vue-router";
import { changeCompany } from '@/states/login'

export default defineComponent({
  name: 'Study',
  components: { Attention, Discover, Recommend },
  setup () {
    const tabList = Object.freeze([
      { id: 0, label: '推荐' },
      { id: 1, label: '关注' },
      { id: 2, label: '发现' }
    ])

    const isreday = ref(false)
    const router = useRouter();
    const companyId = router.currentRoute.value.query?.companyId;
    if(companyId){
      changeCompany(companyId,() => {
        isreday.value = true
      })
    }else{
      isreday.value = true
    }

    let activeTab: Ref<UnwrapRef<number>> = ref(0)
    const changeTab = (_activeTab: number) => {
      activeTab.value = _activeTab
    }
    changeShareInfo({
      title: process.env.VUE_APP_PAGE_TITLE,
      desc: process.env.VUE_APP_PAGE_DESC,
      link: window.location.href,
      imgUrl: process.env.VUE_APP_SHARE_IMG
    })

    return { tabList, changeTab, activeTab,isreday }
  }
})
