import { createModuleApi, Page, PageResponse, Study } from 'momai'

interface Param {
  courseId: string;
  quality: string;
  myFollow: string;
  pageData: Page;
  courseType: string;
  companyId: number;
}

const createParam = (): Param => ({
  courseId: '',
  quality: '',
  myFollow: '',
  courseType: '',
  companyId: 999,
  pageData: {
    current: 1,
    size: 10
  }
})

const studyApi = <T>() => createModuleApi<T>('homework')

const list = ({ courseId, quality, myFollow, courseType, pageData,companyId }: Param) => studyApi<PageResponse<Study>>()({
  subUrl: 'getOtherStuPageWorksNew',
  method: 'post',
  params: {
    ...pageData,
    courseId,
    quality,
    myFollow,
    courseType,
    companyId
  }
})

export { list, Param, createParam }
