
  import { defineComponent, provide, reactive, ref } from 'vue'
import ComBiz from '@/views/study/components/ComBiz.vue'
import { createParam, Param } from '@/api/study';
import { api } from '@/api/useAxios';
import { checkFollow, useLoadListEffect } from '@/views/study/components/comBiz';

interface courseType {
  id: number;
  tagName: string;
  tagType: string;
}

const createCourseTag = ():courseType => ({
  id: -1,
  tagName: '',
  tagType: '',
})
// 获取课程类型
const useCourseTypeEffect = () => {
  const courseTags = reactive<courseType[]>([])
  api.get('/tag/listAllByType/2').then((resp: any) => {
    const result = resp?.map( ( item: courseType) => {
      let courseTag = createCourseTag()
      courseTag.id = item.id
      courseTag.tagName = item.tagName
      courseTag.tagType = item.tagType
      return courseTag
    })
    courseTags.push(...result)
  })
  return courseTags
}

export default defineComponent({
  name: 'Discover',
  components: {
    ComBiz
  },
  setup() {
    const qryParams: Param = reactive(createParam())
    qryParams.pageData.current = 1
    qryParams.pageData.size = 10
    const selectedIndex = ref(-1)
    const courseTags = useCourseTypeEffect()

    let resp = useLoadListEffect(qryParams)
    let loadMore = resp.loadMore
    let study = resp.study
    let finished = resp.finished
    let loading = resp.loading
    const handleCourseType = (index: number) => {
      selectedIndex.value = index
      const courseTag = courseTags[selectedIndex.value]
      qryParams.courseType = courseTag.id + ''
      study.value = []
      loading.value = false
      resp = useLoadListEffect(qryParams)
      loadMore = resp.loadMore
      study = resp.study
      finished = resp.finished
      loading = resp.loading
    }

    const changeFollow = (res: any) => {
      checkFollow(study, res.userId)
    }
    provide('changeFollow', changeFollow)



    return {
      courseTags,
      selectedIndex,
      handleCourseType,
      loadMore,
      finished,
      loading,
      study
    }
  }
})
